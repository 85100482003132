<template>
    <v-content>
        <v-container class="fill-height" blue fluid style="height: 100vh;">
        </v-container>
    </v-content>
</template>

<script>
export default {
    components:{},
    data(){
        return {
        }
    },
    created(){
        this.flogout();
    },
    methods:{
        flogout(){
        //console.log(this.loginData);
        console.log('Logout.vue')
        localStorage.removeItem('token_auth');
        localStorage.removeItem('loginData');
        localStorage.removeItem('login_id');
        localStorage.removeItem('isLogin');
        this.$store.dispatch('aLogout');
        this.$router.push('/login')
        //this.loginData = null;
        
      }        
    }
}
</script>
